::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb { /*Barra*/
  background: #FFFFFF;
  border: 0px none #FFFFFF;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(theme-color("primary"), .25); /*Barra*/
}
::-webkit-scrollbar-thumb:active {
  background: rgba(theme-color("primary"), .35); /*Barra*/
}
::-webkit-scrollbar-track { /*Fundo*/
  background: #FFFFFF;
  border: 0px none #FFFFFF;
  border-radius: 44px;
}
::-webkit-scrollbar-track:hover {
  background: #FFFFFF; /*Fundo*/
}
::-webkit-scrollbar-track:active {
  background: rgba(theme-color("primary"), .20); /*Fundo*/
}
::-webkit-scrollbar-corner {
  background: #FFFFFF;
}

/***** Data Table *****/
.table th, .table td{
  padding: 1rem !important;
  border-top: 1px solid #DEE2E6 !important;
}

/***** Data Table Footer *****/
.card-footer{
  background-color: transparent;
}

/***** Content *****/
.cr-page{
  min-height: 600px;
  &__header{
    margin-top: 80px;
    margin-bottom: 30px;
  }
  &__title{
    width: 100%;
    text-align: center;
    font-family: arial, helvetica, sans-serif;
    color: theme-color("carbono");
    font-size: 32px;
    font-weight: regular;
  }
}

/******* Page Conta *******/
.label-right {
  float: right;
}
.sub-title{
  margin-top: 50px;
  width: 100%;
  text-align: center;
  font-family: arial, helvetica, sans-serif;
  color: theme-color("chumbo");
  font-size: 20px;
  font-weight: regular;
}
.box-button-store-credit-card{
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}
.button-store-credit-card{
  border-radius: 13px;
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
  padding-right: 40px;
  padding-left: 40px;
}
.box-selection-credit-card{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .card-invalid{
    border: red solid 2px !important;
  }

  .credit-card{
    width: 320px;
    height: 235px;
    padding: 11px 0px 0 16px;
    float: left;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    box-shadow: 2px 2px 23px 2px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 2px 2px 23px 2px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 2px 2px 23px 2px rgba(0, 0, 0, 0.51);
  }
  .card-primary{
    border: 1px solid #b3b3b3ad;
    background-color: theme-color("carbono");

    label{
      opacity: 0.80;
      display: block;
      color:  #00A75B;
    }
    .label-input{
      color:  #FFFFFF;
      font-weight: normal;
      opacity: .80;
    }
  }
  .card-secundary{
    border: 1px solid #b3b3b3ad;
    background-color: #00A75B;

    label{
      opacity: 0.80;
      display: block;
      color: theme-color("carbono");
    }
    .label-input{
      color:  #FFFFFF;
      font-weight: normal;
      opacity: .90;
    }
  }
  .selected {
    box-shadow: 0px 0px 30px 5px #007E38;
    -moz-box-shadow: 0px 0px 30px 5px #007E38;
    -webkit-box-shadow: 0px 0px 30px 5px #007E38;
    opacity: 1;
  }
  .delete_card{
    width: 30px;
    height: 30px;
    cursor: pointer;
    float: right;
    margin-top: -260px;
    margin-right: -15px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 1px -1px 16px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px -1px 16px 1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px -1px 16px 1px rgba(0, 0, 0, 0.5);

    .icon-garbage {
      width: 40px;
      height: 20px;
      margin-left: -6px;
      margin-top: 4px;
    }
  }
}
.sub-title-tickets{
  color: #00A75B;
  margin-top: 0;
  margin-bottom: 5%;
  width: 100%;
  text-align: center;
  font-family: arial, helvetica, sans-serif;
  font-size: 20px;
  font-weight: regular;
}
.sub-title-tickets-credit{
  color: black;
}
.title-box-selection-tickets{
  color: gray;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
}
.box-selection-tickets{
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;

  .selected {
    background-color: theme-color("carbono");
    color: #FFFFFF;
    .ticket{
      color: #FFFFFF;
    }
  }
}
.ticket-not-selected{
  border: 1px solid red !important;
}
.text-ticket-not-selected{
  color: red;
  width: 100%;
  text-align: center;
}
.box-select-ticket{
  margin-top: 5%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 80%;
  min-width: 100px;
  border: 1px solid #b3b3b3ad;
  text-align: center;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background-color: #E6E6E6;
  padding-top: 10px;
  cursor: pointer;
  box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.51);

  h3{
    font-weight: 600;
  }
  .ticket{
    cursor: pointer;
    color: theme-color("carbono");
    width: 100%;
  }
  .ticket-value{
    font-size: 15pt;
    cursor: pointer;
    color: #00A75B;
    font-weight: bold;
  }
}

.form-insert{
  margin-top: 30px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

.label-total-value-tickets{
  color: #00A75B;
  text-align: center;
  font-size: 21pt;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
  span{
    color: #000000;
    font-weight: normal;
  }
}

/***** Comprar Tickets *****/
.comprar-tickets {
  .form-insert{
    width: 100%;

    .pay-tickets-text-input{
      width: 100%;
      text-align: center;
    }
  }
  button{
    border-radius: 13px;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 15px;
    margin-left: 15px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .cr-page__header .h1{
    margin-left: 0% !important;
  }

  .box-saldo-comprar-tickets{
    width: 230px;
    height: 290px;
    margin-top: 0;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 12%;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    box-shadow: 3px 3px 26px -4px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 3px 3px 26px -4px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 3px 3px 26px -4px rgba(0, 0, 0, 0.51);

    label{
      margin: 0px;
    }

    .col-text{
      text-align: center;

      .text-session{
        width: 100%;
        text-align: center;
        color: theme-color("secondary");
        font-size: 12pt;
        font-weight: 600;
      }

      .text-values{
        width: 100%;
        text-align: center;
        color: theme-color("carbono");
        font-size: 25pt;
        font-weight: bold;

      }
      .ticket{
        font-size: 32pt;
      }
    }
    .sub-title{
      margin-top: 0px;
      margin-bottom: 15px;
      text-align: center;
      width: 100%;
      font-size: 17pt;
      font-weight: bold;
      color: #000000;
    }
  }
  .form-block{
    width: 100%;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;

    div{
      margin: 20px 38%;
      input{
        width: 190px;
      }
    }
  }
  .box-footer-select-form-payment{
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;

    .text-form-payments{
      color: theme-color("carbono");
      opacity: 0.60;
    }
  }
  .button-action{
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;

    .button-billet{
      background-color: #2E2E2E !important;
      color: #FFFFFF;
    }
    .button-billet:hover{
      background-color: #444444 !important;
      color: #DCDCDC;
    }
  }
  .button-action-message{
    color: red;
    width: 100%;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .action-invalid{
    border: red solid 2px !important;
  }
  .actived{
    box-shadow: 0px 0px 30px 2px #00881F;
    -moz-box-shadow: 0px 0px 30px 2px #00881F;
    -webkit-box-shadow: 0px 0px 30px 2px #00881F;
  }
}
.pag-success-ticket{
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;

  .box-sucesso-ticket{
    padding: 10px;
    min-width: 500px;
    min-height: 560px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left top;

    label{
      text-align: center;
      font-size: 15pt;
      width: 100%;
      color: gray;
    }
    label:first-child{
      margin-top: 10px;
    }
    p{
      text-align: center;
      font-size: 13pt;
      width: 100%;
      color: black;
    }
    div{
      text-align: center;
    }
    span{
      font-size: 28pt;
      font-weight: 700;
      color: theme-color("primary");
    }
  }
  .box-buttons{
    div{
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-justify-content: center;

      button{
        margin-top: 50px;
        width: 140px;
        height: 35px;
        border-radius: 13px;
        -moz-border-radius: 13px;
        -webkit-border-radius: 13px;
      }
    }
  }
}

/******* Footer *******/
.container-fluid .footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}


/******* Style Globals *******/

.marginTop20{
  margin-top: 20px !important;
}
.marginTop60{
  margin-top: 60px !important;
}
.marginTop100{
  margin-top: 100px !important;
}

.marginBottom0{
  margin-bottom: 0 !important;
}
.marginBottom20{
  margin-bottom: 20px !important;
}
.marginBottom50{
  margin-bottom: 50px !important;
}
.marginBottom100{
  margin-bottom: 100px !important;
}
/* unvisited link */
a:link {
  color: #007E38;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: #00A75B;
}

/* selected link */
a:active {
  color: #00A75B;
}